import React, {FunctionComponent, useMemo} from 'react';
import AppLayoutBase from 'aws-northstar/layouts/AppLayout';
import HeaderBase from 'aws-northstar/components/Header';
import SideNavigationBase, { SideNavigationItem, SideNavigationItemType } from 'aws-northstar/components/SideNavigation';
import BreadcrumbGroup from 'aws-northstar/components/BreadcrumbGroup';
import ApiHandler from "../../common/api";
import Link from 'aws-northstar/components/Link';
import Text from 'aws-northstar/components/Text';
import HelpPanel from 'aws-northstar/components/HelpPanel';
import Box from 'aws-northstar/layouts/Box';
import ButtonDropdown from 'aws-northstar/components/ButtonDropdown';
import {ReduxRoot} from "../../interfaces";
import {useSelector} from "react-redux";
import { useHistory } from 'react-router-dom';

const AppLayout: FunctionComponent = ( {children} ) => {
  const queryParams = new URLSearchParams(window.location.search)
  const overrideRole = queryParams.get("override_role")
  const history = useHistory();
  const userInfo = useSelector( (state:ReduxRoot) => {
    return state.breakGlassReducerState.userInfo
  });
  const onLogoffClick = () => {
    history.push('/Logoff');
  }

  
    const menuItems = [
      { text: 'Log off', onClick: onLogoffClick}
    ];
    let rightContent = (<Box display="flex" alignItems='center'>
    </Box>);
    if(userInfo.user){
      rightContent = (<Box display="flex" alignItems='center'>
                <ButtonDropdown content={userInfo.user.split('@')[0]} items={menuItems} darkTheme />
      </Box>);
    }
    const Header = useMemo(() => (
      <HeaderBase title="" logoPath="/pam-2.png" rightContent={rightContent}/>
      // eslint-disable-next-line react-hooks/exhaustive-deps
    ), []);
  
  
  const Breadcrumbs = useMemo(() => <BreadcrumbGroup rootPath=""/>, []);;
  const SideNavigation = useMemo(() => {

    return <SideNavigationBase
        header={{text: 'PAM', href: '/'}}
        items={
          getNavigation()
        }
        ></SideNavigationBase>
        // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  function getNavigation() {
    let navs: Array<SideNavigationItem> = [];

    if (ApiHandler.requester) {
      let nav:SideNavigationItem = {text: 'Request dashboard', type: SideNavigationItemType.LINK, href: '/Request-dashboard'}
      navs.push(nav)
    }

    if (ApiHandler.reviewer || overrideRole === 'reviewer') {
      let nav:SideNavigationItem = {text: 'Review dashboard', type: SideNavigationItemType.LINK, href: '/Review-dashboard'}
      navs.push(nav)
    }

    if (true || ApiHandler.auditor || overrideRole === 'auditor') {
      let nav:SideNavigationItem = {text: 'Audit dashboard', type: SideNavigationItemType.LINK, href: '/Audit-dashboard'}
      navs.push(nav)
    }

    if (ApiHandler.requester || ApiHandler.reviewer || ApiHandler.auditor) {
      let nav:SideNavigationItem = {text: 'Log out', type: SideNavigationItemType.LINK, href: '/Logoff'}
      navs.push(nav)
    }

    return navs;
  }

  const helpPanel = (
    <HelpPanel
        header="Help"
        learnMoreFooter={[
            <Link href="https://accounts.aws.sphdigital.sg/">AWS Accounts</Link>,
            <Link href="https://security.aws.sphdigital.sg/">Security</Link>,
            <Link href="https://d-9667112994.awsapps.com/start#/">AWS SSO New Capital</Link>,
            <Link href="https://d-96671907da.awsapps.com/start#/">AWS SSO Old Capital</Link>,
            <Link href="https://sph.atlassian.net/wiki/spaces/PFMENG/pages/1209040979/AWS+SSO">SSO</Link>,
            <Link href="https://sph.atlassian.net/wiki/spaces/PFMENG/pages/1209040979/AWS+SSO">AWS PAM</Link>
        ]}
    >
        <Text variant="p">
            Click <Link href="https://accounts.aws.sphdigital.sg/">here</Link> to get the AWS Account ID
        </Text>
    </HelpPanel>
);

    return <AppLayoutBase
        header={Header}
        navigation={SideNavigation}
        breadcrumbs={Breadcrumbs}
        helpPanel={helpPanel}
    >
        {children}
    </AppLayoutBase>
}

export default AppLayout;
